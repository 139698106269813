<template>
    <el-dialog 
        title="主账号选择" 
        :visible.sync="dialogVisible" 
        :show-close="false" 
        :close-on-press-escape="false" 
        :close-on-click-modal="false"
        width="600px"
    >
        <el-alert :closable="false" title="请选择需要登录的账号" type="warning" show-icon></el-alert>
        <el-row :gutter="20" class="masterUsers" type="flex" justify="space-around">
            <el-col v-for="(item,index) in masterUsers" :key="index" :span="8" >
                <el-card>
                    <div @click="selectUser(item)">
                        <el-avatar :src="`${item.avatar}`" fit="cover" :size="60"></el-avatar>
                        <div class="name">{{item.name}}</div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
    props:{
        masterUsers:{
            type:Array,
            default:() => null
        }
    },
    data(){
        return{
            dialogVisible: false
        }
    },
    methods:{
        show(){
            this.dialogVisible = true
        },
        selectUser(item){
            this.$emit('selectUser',item);
        }
    }
}
</script>
<style lang="scss" scoped>
.masterUsers{
    margin-top: 10px;
    flex-wrap: wrap;
    .el-card{
        cursor: pointer;
        text-align: center;
        margin: 10px 0;
        .name{
            margin-top: 12px;
        }
    }
    
}
</style>