<template>
    <div class="container">
        <div class="page-box-layout login-content">
            <div class="left hidden-xs-only">
                <router-link to="/" class="title">秀动经营平台</router-link>
                <p>如需要帮助，请联系电话：400-188-6980 周一至周五 10:00~18:30</p>
                <p>©{{new Date().getFullYear()}}成都太合乐动科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备14016442号-4</a></p>
            </div>
            <div class="right">
                <div class="login-box">
                    <div class="title">{{loginType === 1 ? '密码登录' : '短信登录'}}</div>
                    <div class="tips">
                        <span>还没有账号?</span>
                        <router-link to="/register">点击注册</router-link>
                    </div>
                    <el-form :model="formData" :rules="rules" ref="formData" hide-required-asterisk >

                        <template v-if="loginType === 1">
                            <el-form-item prop="account">
                                <el-input v-model="formData.account" placeholder="请输入手机号或账号名"></el-input>
                            </el-form-item>
                            <el-form-item prop="pwd">
                                <el-input v-model="formData.pwd" type="password" placeholder="请输入登录密码"></el-input>
                            </el-form-item>
                        </template>

                        <template v-else>
                            <el-form-item prop="phone">
                                <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                            <el-form-item prop="securityCode">
                                <div class="input-with-button">
                                    <el-input v-model="formData.securityCode" placeholder="请输入短信验证码" ></el-input>
                                    <el-button
                                        native-type="button"
                                        type="text"
                                        @click="getCode()"
                                        :disabled="codeBtn.disabled"
                                        :loading="codeBtn.loading"
                                    >{{codeBtn.btnText}}</el-button>
                                </div>
                            </el-form-item>
                        </template>
                        
                        <el-form-item>
                            <el-button type="primary" class="btn submit-button" @click="submitForm()" :loading="loginLoading">登 录</el-button>
							<div @click="$router.push({path:'/login/lostpwd'})" class="lostpwd">忘记密码</div>
                        </el-form-item>
                    </el-form>
                    <div class="or"><span>或者</span></div>
                    <el-button class="btn default-button" @click="loginTypeHandle">{{loginType === 1 ? '使用短信登录' : '使用密码登录'}}</el-button>
                </div>
            </div>
        </div>
        
        <select-user ref="selectUser" :masterUsers="masterUsers" @selectUser="selectUser"></select-user>

    </div>
</template>

<script>
var md5 = require('js-md5');
import SelectUser from './components/select-user';
import {getMenuList, verificationSwitch} from '@/assets/js/commonApi';
export default {
    components:{SelectUser},
    data(){
        return{
            formData:{
                account: '',
                pwd: '',
                phone:'',
                securityCode:''
            },
            loginType:1,
            rules: {
                account: [{ required: true, message: "请输入手机号或账号名" }],
                pwd:[{ required: true, message: "请输入登录密码" }],
                phone: [
                    { required: true, message: "请输入手机号" },
                    { validator: this.$utils.validatePhone },
                ],
                securityCode:[{ required: true, message: "请输入短信验证码" }],
            },
            codeBtn: {
                btnText: "获取验证码",
                disabled: false,
                loading: false
            },
            loginLoading: false,
            captcha: null,
            redirect: this.$route.query.redirect || '/manage/home',
            masterUsers:[],
        }
    },
    created() {
        const that = this;
        this.$utils.loadScript(
            {
                id:'tcaptcha',
                url:'https://turing.captcha.qcloud.com/TCaptcha.js'
            },
            () =>{
                // eslint-disable-next-line no-undef
                this.captcha = new TencentCaptcha(
                    "2032912416", 
                    function(res){
                        if(res.ret === 0){
                            that.getCodeEvent( res );
                        }
                    }
                );
            }
        )
    },
    methods:{
        loginTypeHandle(){
            this.loginType = this.loginType === 1 ? 2 : 1;
            this.$nextTick(() => {
                this.$refs.formData.clearValidate();
            })
        },
        //获取短信验证码
        getCode() {
            this.$refs["formData"].validateField(["phone"],async (err) => {
                if(!err){
                    const res = await  verificationSwitch(); //获取验证码设置
                    const { state, msg, result } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "获取验证码设置失败");
                    } else {
                        if( result.suduLogin ){
                            this.captcha.show();
                        }else{
                            this.getCodeEvent();
                        }
                    }
                }
            });
        },
        getCodeEvent(_res){
            this.codeBtn.loading = true;
            this.$request({
                url: "/user/login/securityCode",
                method: "POST",
                params: {
                    phone: this.formData.phone,
                    randStr:_res && encodeURIComponent(_res.randstr) || '',
                    ticket: _res && encodeURIComponent(_res.ticket) || '',
                },
            })
                .then((res) => {
                    const { state, msg } = res.data;
                    if (state != 1) {
                        this.$message.error(msg || "请求失败");
                    } else {
                        // this.formData.securityCode = res.data.result;
                        this.dtime(60)
                    }
                })
                .catch(() => {
                    this.$message.error('请求错误');
                }).finally( () => {
                    this.codeBtn.loading = false;
                });
        },
        dtime( time ){
            var that = this , _time = time;
            if( _time <= 0 ){
                this.codeBtn.btnText = '获取验证码';
                this.codeBtn.disabled = false;
            }else{
                this.codeBtn.btnText =  _time+'秒后重试' ;
                _time --;
                this.codeBtn.disabled = true;
                let t1 = setTimeout(function(){ that.dtime( _time );clearTimeout(t1); } , 1000);
            }
        },
        //提交表单
        submitForm() {
            let self = this;
            self.$refs["formData"].validate((valid) => {
                if (valid) {
                    this.loginLoading = true;
                    const _url = this.loginType === 1 ? '/user/login/byPwd' : '/user/login/byPhone' 
                    this.$request({
                        url: _url,
                        method: "POST",
                        params: {...this.formData,pwd:md5(this.formData.pwd).toLowerCase(),systime:new Date().getTime()}
                    }).then( res => {
                        const { state, msg, result } = res.data;
                        if (state != 1) {
                            this.$message.error(msg || "请求失败");
                        } else {

                            self.$store.commit( 'setCUSUT', result.session.token || null );
                            
                            if(result.isMaster != 1 && result.isChoose == 1){
                                this.masterUsers = result.masterUsers;
                                this.$refs.selectUser.show();
                            }else{
                                if( result.masterUsers.length > 0 ){
                                    self.selectUser(result.masterUsers[0]);
                                }else{
                                    self.selectUser(result.session);
                                }
                            }

                            // if((result.isMaster == 1 || result.masterUsers.length == 1) && result.isChoose != 1){ //主账号或主账号只有一个主账号
                            //     self.selectUser(result.masterUsers[0]);
                            // }else{ // 子账号且有多个主账号
                            //     this.masterUsers = result.masterUsers;
                            //     this.$refs.selectUser.show();
                            // }
                        }
                    }).catch(() => {
                        this.$message.error('请求错误');
                    }).finally( () => {
                        this.loginLoading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        async selectUser(item){
            this.$store.commit('setUserInfo',item);
            await getMenuList();//获取登录用户拥有的菜单权限
            if( item.userType == 1 || item.type == 1 ){
                this.$router.push('/');
            }else{
                this.$router.push(this.redirect);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.container{
    background: url('~@/assets/img/login/bg.png') center center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    min-height: 100vh;
    @media screen and (max-width: $--screen-sm) {
        background: #000;
    }
    .login-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            color: #ffffff;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            .title{
                font-size: 56px;
                margin-bottom: 260px;
                color: #ffffff;
                display: block;
            }
            p{
                font-size: 13px;
                line-height: 22px;
            }
            a{
                color: #ffffff;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .right{
            background: #ffffff;
            border-radius: 4px;
            padding: 50px 80px 100px 80px;
            @media screen and (max-width: $--screen-md) {
                padding: 50px 40px;
            }
            .login-box{
                width: 356px;
                @media screen and (max-width: $--screen-md) {
                    width: 280px;
                }
				.lostpwd{
					font-size:12px;
					cursor:pointer;
					&:hover{
						color:#145AF2
					}
				}
                .title{
                    font-size: 36px;
                    font-weight: 600;										
                }
                .tips{
                    font-size: 13px;
                    color: $--color-info;
                    margin-top: 12px;
                    a{
                        color: $--color-theme;
                    }
                }
                .el-form{
                    margin-top: 60px;
                    .input-with-button{
                        position: relative;
                        .el-button{
                            position: absolute;
                            right: 0;
                            top: 0;
                            padding: 12px 20px;
                        }
                    }
                    // .input-with-select{
                    //     ::v-deep .el-input__inner{
                    //         // border-right: 0;
                    //     }
                    //     ::v-deep .el-input-group__append{
                    //         background: none;
                    //     }
                    // }
                }
                .btn{
                    width: 100%;
                    &.submit-button{
                        margin-top: 26px;
                    }
                    &.default-button{
                        ::v-deep span{
                            font-weight: 600;
                        }
                    }
                }
                .or{
                    position: relative;
                    text-align: center;
                    margin: 30px 0;
                    span{
                        padding: 0 20px;
                        background: #ffffff;
                        position: relative;
                        z-index: 1;
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        height: 1px;
                        background: $--border-color-base;
                        width: 100%;
                        left: 0;
                        top: 50%;
                    }
                }
            }
        }
    }
}
</style>